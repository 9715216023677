import { getGeoPdfFile, type PostGeoPDF } from '_api/geopdf/service';
import InputFileButton from 'components/buttons/InputFileButton';
import { getShpBase64 } from 'datacosmos/download/geojson';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import {
  DefaultDPI,
  DefaultPaperSize,
  PapersizesForGeoPDF,
} from 'datacosmos/utils/views';
import { HOST_URL } from 'env';
import { Formik, type FormikProps } from 'formik';
import type { BBox } from 'geojson';
import { isEmpty } from 'lodash';
import {
  Button,
  Dialog,
  Icon,
  ListBoxItem,
  RichTextEditor,
  Select,
} from 'opencosmos-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import { downloadFile } from 'utils/common/CommonUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { getMapScreenshot, loadMapFromIframe } from 'utils/screenshot';

interface GeoPDFProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface shpURL {
  id: string;
  shp: string;
}
const GeoPDFDetails = ({ isOpen, setIsOpen }: GeoPDFProps) => {
  const { translate } = useLocalisation();
  const [selectedPaperSize, setSelectedPaperSize] =
    useState<string>(DefaultPaperSize);

  const [selectedOrientation, setSelectedOrientation] = useState<string>(
    translate('datacosmos.geopdfDialog.orientationValues.landscape')
  );

  interface FormValues {
    dpi: string;
    description: string;
    title: string;
    logo?: string;
  }

  const initialValues: FormValues = {
    dpi: DefaultDPI,
    description: '',
    title: '',
    logo: undefined,
  };

  const formRef = useRef<FormikProps<FormValues>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedLogoFileName, setUploadedLogoFileName] = useState<string>('');
  const [shpURLsForVectors, setShpURLsForVectors] = useState<shpURL[]>();
  const { layers } = useMapLayers();
  const { user } = useAuth();
  const { getMapBounds } = useMap();

  const mapBbox = getMapBounds();

  const logoURL = `${HOST_URL}/images/datacosmos/conida-logo.png`;

  const shouldDisplayWarning = useMemo(() => {
    const stacLayers = layers.filter(
      (l) => l instanceof SingleBandSTACLayer
    ) as SingleBandSTACLayer[];
    const nonTiffLayers = stacLayers.filter(
      (layer) => !layer.item.assets[layer.assetKey].type?.includes('image/tiff')
    );
    return !isEmpty(nonTiffLayers);
  }, [layers]);

  const adjustBoundsToAspectRatio = (
    [swLng, swLat, neLng, neLat]: BBox,
    targetAspectRatio: number
  ): BBox => {
    const currentWidth = neLng - swLng;
    const currentHeight = neLat - swLat;
    const currentAspectRatio = currentWidth / currentHeight;

    if (currentAspectRatio === targetAspectRatio) {
      return [swLng, swLat, neLng, neLat]; // No adjustment needed
    }

    // Calculate the desired height to match target aspect ratio
    const desiredHeight = currentWidth / targetAspectRatio;
    const heightDifference = desiredHeight - currentHeight;
    const adjustment = heightDifference / 2;

    // Adjust and clamp the latitude values within -85.0511 and 85.0511
    const adjustedNorthLat = Math.min(neLat + adjustment, 85.0511);
    const adjustedSouthLat = Math.max(swLat - adjustment, -85.0511);

    return [swLng, adjustedSouthLat, neLng, adjustedNorthLat];
  };

  //filters the layers that do not contain tiff assets
  const layersToBeAddedInGeoPDF = useMemo(() => {
    return layers.filter(
      (mapLayer) =>
        (mapLayer instanceof SingleBandSTACLayer &&
          mapLayer.item.assets[mapLayer.assetKey].type?.includes(
            'image/tiff'
          )) ||
        !(mapLayer instanceof SingleBandSTACLayer)
    );
  }, [layers]);

  useEffect(() => {
    if (!isOpen || !layersToBeAddedInGeoPDF.length) {
      return;
    }

    const vectorLayers = layersToBeAddedInGeoPDF.filter(
      (mapLayer) =>
        mapLayer instanceof PolygonLayer ||
        mapLayer instanceof CircleLayer ||
        mapLayer instanceof LineLayer ||
        mapLayer instanceof GeoJSONLayer
    ) as PolygonLayer[];

    void Promise.all(
      vectorLayers.map(async (addedLayer) => {
        return {
          id: addedLayer.id,
          shp: (await getShpBase64(
            addedLayer.data as GeoJSON.Feature
          )) as string,
        };
      })
    ).then((vectorURLArray) => {
      // vectorURLArray contains the resolved values
      setShpURLsForVectors(vectorURLArray);
    });
  }, [layersToBeAddedInGeoPDF, isOpen]);

  const getLayersForPayload = useCallback(
    async (coords: BBox, width: number, height: number) => {
      const x1 = 0.025 * width;
      const y1 = 0.04 * height;

      const x2 = (3 * width) / 4.05;
      const y2 = height - y1;

      const adjustedWidth = x2 - x1;
      const adjustedHeight = y2 - y1;

      const adjustedBbox = adjustBoundsToAspectRatio(
        coords,
        adjustedWidth / adjustedHeight
      );

      await loadMapFromIframe(adjustedBbox, adjustedWidth, adjustedHeight, {
        scale: true,
        id: 'screenshot-map',
      });

      let geojson;
      const mapLayers = layersToBeAddedInGeoPDF.map((layer) => {
        if (layer instanceof SingleBandSTACLayer) {
          geojson = layer.item.geometry;
          return {
            enabled: true,
            georeferencing: {
              bbox: adjustedBbox,
            },
            raster: tilingApi.generateSingleBandBboxURL(
              `item=${layer.item.id}&collection=${layer.item.collection}&assets=${layer.assetKey}`,
              {
                bbox: adjustedBbox,
                width: width,
                height: height,
              },
              layer.options
            ),
          };
        }
        if (layer instanceof BandAlgebraSTACLayer && layer.item.collection) {
          geojson = layer.item.geometry;
          const formattedExpression =
            layer.expression.split('::').length > 1
              ? layer.expression.split('::')[1]
              : layer.expression.split('::')[0];
          return {
            enabled: true,
            georeferencing: {
              bbox: adjustedBbox,
            },
            raster: tilingApi.generateBandAlgebraBboxURL(
              `&item=${layer.item.id}&collection=${layer.item.collection}`,
              formattedExpression,
              {
                bbox: adjustedBbox,
                width: width,
                height: height,
              },
              layer.options
            ),
          };
        }
        if (
          layer instanceof CircleLayer ||
          layer instanceof PolygonLayer ||
          layer instanceof LineLayer ||
          layer instanceof GeoJSONLayer
        ) {
          geojson = layer.data;
          return {
            enabled: true,
            georeferencing: {
              bbox: adjustedBbox,
            },
            vector: shpURLsForVectors?.find((v) => v.id === layer.id)?.shp,
          };
        }
        return {
          enabled: true,
          georeferencing: {
            bbox: adjustedBbox,
          },
        };
      });

      const marginY = 0.037 * Number(height);
      const mapHeight = Number(height) / 3 - 2 * marginY;

      const marginX = 0.02 * Number(width);
      const mapWidth = 0.23 * Number(width) - 2 * marginX;

      const minimapBbox = adjustBoundsToAspectRatio(
        [-87.363281, -22.024546, -64.599609, 2.569939],
        mapWidth / mapHeight
      );

      await loadMapFromIframe(minimapBbox, mapWidth, mapHeight, {
        scale: false,
        id: 'screenshot-minimap',
        vector: geojson,
      });

      const mapBaseLayer = {
        enabled: true,
        raster: '',
        georeferencing: {
          bbox: adjustedBbox,
        },
      };

      const base64MinimapScreenshot = await getMapScreenshot(
        Math.floor(mapWidth)?.toString(),
        Math.floor(mapHeight)?.toString(),
        document.getElementById('screenshot-minimap') as HTMLIFrameElement
      );

      const base64Screenshot = await getMapScreenshot(
        Math.floor(adjustedWidth)?.toString(),
        Math.floor(adjustedHeight)?.toString(),
        document.getElementById('screenshot-map') as HTMLIFrameElement
      );

      if (base64Screenshot) {
        mapBaseLayer.raster = base64Screenshot;
      }

      return {
        layers: [mapBaseLayer, ...mapLayers.reverse()],
        minimap: base64MinimapScreenshot,
      };
    },
    [layersToBeAddedInGeoPDF, shpURLsForVectors]
  );

  return (
    <>
      <Dialog
        title={translate('datacosmos.geopdfDialog.geoPDFHeader')}
        isOpen={isOpen}
        buttons={[
          {
            text: translate('datacosmos.geopdfDialog.createGeoPDF'),
            onPress: () => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            },
            shown: true,
            showLoadingIndicator: loading,
            keepDialogOpenOnPress: true,
            intent: 'primary',
            className: 'hover:text-neutral-900',
          },
        ]}
        onClose={() => {
          setIsOpen(!isOpen);
          setSelectedPaperSize(DefaultPaperSize);
          setSelectedOrientation(
            translate('datacosmos.geopdfDialog.orientationValues.landscape')
          );
          setUploadedLogoFileName('');
          setLoading(false);
        }}
        showButtonsInFooter
        cancelButtonText={translate('datacosmos.buttons.cancel')}
      >
        <>
          {shouldDisplayWarning && (
            <div className="flex items-center gap-2 bg-accent-300 dark:bg-accent-dark my-2 p-1 text-xs dark:text-item-contrast">
              <Icon icon="Info" />
              <span>{translate('datacosmos.geopdfDialog.nonTiffWarning')}</span>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            innerRef={formRef}
            onSubmit={async (values, { setErrors, resetForm }) => {
              if (values.dpi === '') {
                setErrors({
                  dpi: translate(
                    'datacosmos.addNewProjectDialog.errors.description'
                  ),
                });
                return;
              }

              if (values.description === '') {
                setErrors({
                  description: translate(
                    'datacosmos.addNewProjectDialog.errors.description'
                  ),
                });
                return;
              }

              if (values.title === '') {
                setErrors({
                  title: translate('datacosmos.geopdfDialog.errors.title'),
                });
                return;
              }

              const width =
                PapersizesForGeoPDF[selectedPaperSize][values.dpi][
                  selectedOrientation ===
                  translate(
                    'datacosmos.geopdfDialog.orientationValues.portrait'
                  )
                    ? 0
                    : 1
                ];
              const height =
                PapersizesForGeoPDF[selectedPaperSize][values.dpi][
                  selectedOrientation ===
                  translate(
                    'datacosmos.geopdfDialog.orientationValues.portrait'
                  )
                    ? 1
                    : 0
                ];

              if (!user || !mapBbox) {
                return;
              }
              setLoading(true);

              const geopdfPayload = await getLayersForPayload(
                mapBbox,
                width,
                height
              );
              const geoPDFRequestBody: PostGeoPDF = {
                metadata: {
                  author: user.name,
                },
                pages: [
                  {
                    settings: {
                      dpi: values.dpi,
                      width: width?.toString(),
                      height: height?.toString(),
                      has_logo: true,
                      has_minimaps: true,
                    },
                    content: {
                      description: values.description,
                      title: values.title,
                      minimap: geopdfPayload.minimap,
                      logos: [logoURL, values.logo ?? ''],
                      layers: geopdfPayload.layers,
                    },
                  },
                ],
              };

              await getGeoPdfFile({
                body: geoPDFRequestBody,
              })
                .then((res) => {
                  if (res.status === 200) {
                    downloadFile(
                      res.data as BlobPart,
                      `geopdf-${values.dpi}-${width}x${height}.pdf`
                    );
                    resetForm();
                    setUploadedLogoFileName('');
                  }
                  setIsOpen(false);
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <>
                <div className="grid grid-cols-2 gap-2">
                  <Select
                    selectedKey={selectedPaperSize}
                    onSelectionChange={(size) => {
                      setSelectedPaperSize(size as string);
                    }}
                    placeholder={translate(
                      'datacosmos.geopdfDialog.paperSizePlaceholder'
                    )}
                    fill
                    label={translate('datacosmos.geopdfDialog.paperSizeLabel')}
                    defaultSelectedKey={DefaultPaperSize}
                  >
                    {Object.entries(PapersizesForGeoPDF).map((item) => (
                      <ListBoxItem id={item[0]} key={item[0]}>
                        {item[0]}
                      </ListBoxItem>
                    ))}
                  </Select>

                  <Select
                    selectedKey={selectedOrientation}
                    onSelectionChange={(or) => {
                      setSelectedOrientation(or as string);
                    }}
                    placeholder={translate(
                      'datacosmos.geopdfDialog.orientationPlaceholder'
                    )}
                    fill
                    label={translate(
                      'datacosmos.geopdfDialog.orientationLabel'
                    )}
                    defaultSelectedKey={translate(
                      'datacosmos.geopdfDialog.orientationValues.landscape'
                    )}
                  >
                    <ListBoxItem
                      id={translate(
                        'datacosmos.geopdfDialog.orientationValues.portrait'
                      )}
                      key={translate(
                        'datacosmos.geopdfDialog.orientationValues.portrait'
                      )}
                    >
                      {translate(
                        'datacosmos.geopdfDialog.orientationValues.portrait'
                      )}
                    </ListBoxItem>
                    <ListBoxItem
                      id={translate(
                        'datacosmos.geopdfDialog.orientationValues.landscape'
                      )}
                      key={translate(
                        'datacosmos.geopdfDialog.orientationValues.landscape'
                      )}
                    >
                      {translate(
                        'datacosmos.geopdfDialog.orientationValues.landscape'
                      )}
                    </ListBoxItem>
                  </Select>
                </div>

                <div className="grid grid-cols-2 gap-2 mt-3">
                  <div>
                    <Select
                      selectedKey={values.dpi}
                      onSelectionChange={(size) => {
                        void setFieldValue('dpi', size);
                      }}
                      placeholder={translate(
                        'datacosmos.geopdfDialog.dpiPlaceholder'
                      )}
                      fill
                      label="DPI"
                      isDisabled={!selectedPaperSize}
                      //by default 72 is set for all GeoPDF
                      defaultSelectedKey={DefaultDPI}
                    >
                      {Object.keys(PapersizesForGeoPDF[selectedPaperSize]).map(
                        (dpi) => (
                          <ListBoxItem id={dpi} key={dpi}>
                            {dpi}
                          </ListBoxItem>
                        )
                      )}
                    </Select>
                    <small style={{ color: 'red' }}>
                      {errors.dpi?.toString()}
                    </small>
                  </div>
                  <div>
                    <div className="flex justify-between items-center mb-1">
                      <label htmlFor="geopdf-logo-upload">Logo</label>
                      {Boolean(uploadedLogoFileName?.length) && (
                        <Button
                          icon="Trash"
                          isMinimal
                          isTransparent
                          size="sm"
                          onPress={() => {
                            void setFieldValue('logo', '');
                            setUploadedLogoFileName('');
                          }}
                        >
                          {uploadedLogoFileName}
                        </Button>
                      )}
                    </div>
                    <InputFileButton
                      id="geopdf-logo-upload"
                      text={translate('datacosmos.buttons.upload')}
                      icon="Upload"
                      onChange={(e) => {
                        const files = e.target.files;

                        if (files && files[0]) {
                          const reader = new FileReader();
                          reader.onload = (event) => {
                            const fileDataUrl = event.target?.result;
                            void setFieldValue('logo', fileDataUrl);
                            setUploadedLogoFileName(files[0].name);
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <RichTextEditor
                    value={values.title}
                    label={{
                      position: 'top',
                      text: translate('datacosmos.geopdfDialog.title'),
                    }}
                    onChange={(val) => void setFieldValue('title', val)}
                    placeholder={translate(
                      'datacosmos.geopdfDialog.titlePlaceholder'
                    )}
                  />

                  <small style={{ color: 'red' }}>
                    {errors.title?.toString()}
                  </small>
                </div>
                <div className="mt-3" id="description-editor">
                  <RichTextEditor
                    value={values.description}
                    label={{
                      text: translate(
                        'datacosmos.geopdfDialog.descriptionLabel'
                      ),
                      position: 'top',
                    }}
                    onChange={(val) => void setFieldValue('description', val)}
                    placeholder={translate(
                      'datacosmos.geopdfDialog.descriptionPlaceholder'
                    )}
                  />

                  <small style={{ color: 'red' }}>
                    {errors.description?.toString()}
                  </small>
                </div>
              </>
            )}
          </Formik>
        </>
      </Dialog>
    </>
  );
};

export default GeoPDFDetails;
